.artnight {
  .artnight-header {
    padding-bottom: 30px;
    max-width: 450px;
    margin: auto;
  }

  h1,
  p {
    text-align: center;
  }

  h2 {
    padding-top: 20px;
  }

  h4,
  .video-description {
    text-align: left;
  }

  img {
    width: 100%;
  }

  .makoto-link {
    color: $color-black;
  }

  .artnight-content {
    text-align: center;
  }

  .video-wrapper {
    padding-top: 30px;
  }

  .video-description {
    padding: 5px 0 18px;
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-bottom: 60px;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-display: auto;
  src: url("../assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: auto;
  src: url("../assets/fonts/Roboto-Regular.ttf");
}

h1 {
  font-size: 28px;
  line-height: 1.2;
  padding-bottom: 10px;
  // border-bottom: 1px solid #242222;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

p,
span {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  margin: 8px 0;
}

span,
p {
  line-height: 1.5em;
  padding: 10px 0;
}

.navbar {
  position: absolute;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  z-index: 100;
  background: #242222;

  &.navbar-home {
    position: fixed;
    top: 0;
    transition: top 0.3s ease-in-out;

    @media screen and (min-width: $breakpoint-tablet) {
      background-color: transparent;
    }
  }

  &.scroll {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: $color-black;
  }

  .navbar-ctn {
    display: flex;
    align-items: center;
    padding: 15px 0;

    .navbar-logo {
      height: 100%;
      width: 100%;
      color: #000;
      justify-self: start;
      margin-left: 20px;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
    }

    img {
      height: 100%;
      padding: 10px 0;
    }

    .fa-bars {
      color: $color-white;
    }
    .fa-chevron-down {
      position: relative;
      top: 1px;
      left: 3px;
    }
  }

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 100%;
    font-family: "Roboto", sans-serif;
    padding-top: 18px;

    @media screen and (min-width: $breakpoint-tablet) {
      justify-content: end;
    }

    .nav-item {
      display: flex;
      align-items: center;
      height: 80px;

      @media screen and (max-width: $breakpoint-tablet) {
        justify-content: center;
      }

      .nav-links {
        color: $color-white;
        text-decoration: none;
        padding: 0.5rem 1rem;
        font-size: 16px;
      }
    }
  }

  .nav-links-mobile {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  // .dropdown-menu {
  //   width: 200px;
  //   position: absolute;
  //   top: 80px;
  //   list-style: none;
  //   text-align: start;
  //   z-index: 10;

  //   li {
  //     background: #f2f2f2;
  //     cursor: pointer;

  //     &:hover {
  //       background: rgb(158, 150, 150);

  //       .dropdown-link {
  //         color: rgb(54, 53, 53);
  //       }
  //     }
  //   }

  //   .dropdown-link {
  //     display: block;
  //     height: 100%;
  //     width: 100%;
  //     text-decoration: none;
  //     color: #525252;
  //     padding: 16px;
  //   }

  //   &.clicked {
  //     display: none;
  //   }
  // }

  @media screen and (max-width: $breakpoint-tablet) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;

      &.active {
        background: $color-black;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
    }

    .nav-links {
      text-align: center;
      padding: 2rem;
      display: table;

      @media screen and (min-width: $breakpoint-tablet) {
        width: 100%;
      }

      &:hover {
        // background-color: #f2f2f2;
        // border-radius: 0;
        color: #555555;
      }
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }

    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #555555;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;

      &:hover {
        background: #fff;
        color: #555555;
        transition: 250ms;
      }
    }

    button {
      display: none;
    }
  }
}

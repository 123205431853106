@import "./breakpoints";
@import "./typography";
@import "./color";

// Pages
@import "./artnight.scss";

// Components
@import "./components/navbar";
@import "./components/footer";

@import "./components/hero";
@import "./components/about";
@import "./components/contact";
@import "./components/events";


// ==========================
// Layout - Global
// ==========================
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
}

.container {
  width: 100%;
  height: 100%;
  max-width: $breakpoint-desktop;
  margin: auto;
}

.row {
  display: flex;

  @media screen and (max-width: $breakpoint-tablet) {
    display: block;
  }
}

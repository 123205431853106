#contact {
  .row {
    position: relative;
    justify-content: center;
  }

  .contact-details {
    > div {
      background-color: $color-white;
      padding: 50px 40px;
      z-index: 1;
      margin: 0 20px;

      .contact-item {
        max-width: 250px;
      }

      a {
        display: flex;
        color: $color-black;
        text-decoration: none;
        line-height: 1.3;

        img {
          max-width: 18px;
          margin-right: 18px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media screen and (min-width: $breakpoint-tablet) {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      max-width: 1025px;

      // > div {
      //   background-color: $color-white;
      //   padding: 30px;
      //   z-index: 1;

      //   a {
      //     display: flex;
      //     color: $color-black;
      //     text-decoration: none;
      //     line-height: 1.3;

      //     img {
      //       max-width: 18px;
      //       margin-right: 18px;
      //     }

      //     &:hover {
      //       text-decoration: underline;
      //     }
      //   }
      // }
    }
  }

  .contact-map {
    position: relative;
    width: 100%;
    height: 600px;

    @media screen and (max-width: $breakpoint-tablet) {
      height: 400px;
    }
  }

  .mapboxgl-marker {
    position: absolute;
    top: 0;
  }
}

// #contact {
//   width: 100%;
//   padding: 0;
//   background-color: $color-white;

//   .row {
//     position: relative;

//     @media screen and (min-width: $breakpoint-tablet) {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   }

//   .contact-details {
//     margin: auto;
//     padding: 60px 40px;

//     > div {
//       background-color: $color-white;

//       > div {
//         // display: flex;
//         // align-items: center;
//         // padding: 6px 0;
//         // padding-top: 10px;

//         // img {
//         //   padding-right: 18px;
//         // }

//         // a {
//         //   color: $color-black;
//         //   text-decoration: none;
//         //   line-height: 1.3;

//         //   &:hover {
//         //     text-decoration: underline;
//         //   }
//         // }
//       }
//     }

//     @media screen and (min-width: $breakpoint-tablet) {
//       position: absolute;
//       width: 100%;
//       max-width: 1025px;
//       display: flex;

//       > div {
//         padding: 40px;
//       }
//     }
//   }

//   .contact-map {
//     // height: 600px;
//     width: 100%;

//     // // .map-container {
//       // position: absolute;
//       // top: 0;
//       // bottom: 0;
//       // left: 0;
//       // right: 0;
//     // // }

//     // @media screen and (max-width: $breakpoint-tablet) {
//     //   height: 400px;
//     // }
//   }
// }

#events,
.events {
  width: 100%;
  height: 100%;
  max-width: 1080px;
  padding: 60px 30px 30px;
  margin: auto;

  @media screen and (min-width: $breakpoint-desktop) {
    padding: 100px 30px;
  }

  &.events-page {
    padding: 140px 30px;

    .events-wrapper .event:not(:last-of-type) {
      padding-bottom: 35px;
    }
  }

  .events-wrapper {
    &:first-of-type {
      padding-bottom: 40px;
    }

    .event {
      position: relative;

      @media screen and (min-width: $breakpoint-tablet) {
        display: flex;
      }

      &:not(:last-of-type) {
        padding-bottom: 20px;
      }

      img {
        width: 100%;
        object-fit: cover;
        object-position: top;
        max-height: 400px;
        border-radius: 2px;

        @media screen and (min-width: $breakpoint-tablet) {
          // max-width: 400px;
          height: 250px;
        }
      }
    }
  }

  .fa-chevron-right {
    font-size: 12px;
    position: relative;
    left: 5px;
  }
}

// ==========================
// Event details page
// ==========================

#event-details {
  .details-page {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 80px);
    max-width: 900px;
    margin: auto;
    padding: 9em 20px 60px 20px;

    &__img {
      text-align: center;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        max-height: 500px;
        object-fit: cover;
      }
    }

    &__info {
      padding: 25px 20px;

      @media screen and (max-width: $breakpoint-mobile) {
        padding: 20px 10px;
      }

      &__description {
        padding-top: 10px;
      }

      &__category {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px 15px;
        border-radius: 10px;
        display: inline-block;
        color: $color-white;
        background: $color-black;
      }
    }
  }
}

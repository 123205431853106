#about {
  .about-video-wrapper {
    background-color: $color-black;

    .about-video {
      max-width: 850px;
      margin: auto;
      padding: 40px 0;

      iframe {
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
          min-height: 425px;
        }
      }
    }
  }

  .react-parallax-content {
    background-color: rgba(255, 255, 255, 0.85);
  }

  .about-content {
    display: flex;
    padding: 40px;

    > div {
      max-width: 850px;
      padding: 40px 0;
      margin: auto;
    }

    @media screen and (min-width: $breakpoint-desktop) {
      padding: 40px 20px;
      min-height: 550px;

      > div {
        padding: 40px;
      }
    }
  }
}
